import { useLocation } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Buttons from "../components/common/button"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import handleFormSubmit from "../components/common/FormBuilder/Form-Function"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import DoubleNavbar from "../components/DoubleNavbar"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/magazine.scss"
import Popper from "./../assets/images/party-popper.svg"

const FlipBook = ({ link, isOpen, setIsOpen }) => {
  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={() => setIsOpen(false)} className="close-btn">
          ✕
        </button>
        <div className="iframe-container">
          <iframe
            src={link}
            seamless="seamless"
            scrolling="no"
            frameBorder="0"
            allowTransparency="true"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

const Magazine = () => {
  const [success, setSuccess] = useState(false)
  const currentLocation = useLocation()
  const path = currentLocation.href
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenOld, setIsOpenOld] = useState(false)
  const [link, setLink] = useState("")
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "First name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    // {
    //   name: "linkedin_profile_link",
    //   type: "url",
    //   required: false,
    //   label: "LinkedIn Profile",
    //   errorMessage: "Please enter a valid url",
    // },
    {
      name: "interested_for_magazine",
      type: "checkbox",
      required: false,
      choices: ["Tick here if you want to contribute or be featured"],
      data: ["Yes"],
    },
  ]
  const toggleIsOpen = link => {
    setIsOpen(true)
    setLink(link)
  }
  const checkLocalStorage = () => {
    const data = localStorage.getItem("on_demand_magazine_user_form_inputs_new")
    if (data) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }

  useEffect(() => {
    checkLocalStorage()
  }, [])

  const handleAfterSubmit = (pdfFile, title, response, items) => {
    setSuccess(true)
    // downloadAsset(pdfFile?.url, title)
    localStorage.setItem(
      "on_demand_magazine_user_form_inputs_new",
      JSON.stringify(items)
    )
    checkLocalStorage()
    toggleIsOpen(pdfFile)
  }
  const internalDownload = (picture, text) => {
    let final_data = localStorage.getItem(
      "on_demand_magazine_user_form_inputs_new"
    )
    if (final_data) {
      final_data = JSON.parse(final_data)
    } else {
      final_data = []
    }
    final_data = final_data.filter(item => item.name !== "magazine_name")
    final_data.push({
      name: "magazine_name",
      value: "Edition 2",
    })
    handleFormSubmit(
      final_data,
      process.env.HUBSPOT_MAGAZINE_ENDPOINT,
      document.URL,
      `Magazine - Edition 2`
    )
    // downloadAsset(picture, text)
    toggleIsOpen(picture)
  }
  return (
    <>
      <StaticQuery
        query={graphql`
          query magazine {
            SuperOps {
              pages(where: { title: "Magazine" }) {
                title
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    heading {
                      text
                    }
                    images {
                      url
                    }
                    pdfFile: backgroundImage {
                      url
                    }
                    primaryButtonText
                    heroCtaText
                    heroExcerpt
                    headingLine1
                    headingLine2
                  }
                  ... on SuperOps_Card {
                    text
                    image {
                      url
                    }
                    picture {
                      url
                    }
                    subtext
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              const bugle = newField[1]
              const cta = newField[2]
              return (
                <div className="magazine">
                  <Frame bigFooter seo={seo}>
                    <DoubleNavbar navType="double-nav" />

                    <section className="main-section">
                      <Container className="Layout-container">
                        <div className="d-flex justify-content-between flex-wrap">
                          <Col lg={5} className="hero">
                            <div className="d-inline-flex tag align-items-center down-to-up-1">
                              <SVGIcon
                                name="philosophy/star"
                                className="star"
                              />
                              <p className="d-inline font-white text-uppercase fw-bold m-0 word">
                                {hero.tag}
                              </p>
                            </div>
                            <div className="img-wrapper mt40  down-to-up-2">
                              <img
                                src={hero.images[0].url}
                                alt="bugle"
                                className="w-100"
                              />
                            </div>
                            <div className="altv3 mt56  down-to-up-3">
                              <h1 className="font-white fw-600 font-roboto m-0">
                                {hero.heading.text}
                              </h1>
                            </div>
                            <p className="content one mt32  down-to-up-4">
                              {hero.headingLine1}
                            </p>
                            <p className="content two mt40  down-to-up-5">
                              {hero.headingLine2}
                            </p>
                          </Col>
                          <Col lg={6}>
                            <div className="form-wrapper  down-to-up-2">
                              <div className="form-img position-relative">
                                <img
                                  src={hero.images[2].url}
                                  className="w-100 image"
                                  alt="book"
                                />
                              </div>

                              {!success ? (
                                <FormBuilder
                                  id="ebook-form"
                                  formClassName="forms"
                                  errorClassName="error-message"
                                  inputErrorClassName="error-active"
                                  data={formdata}
                                  buttonText={
                                    success
                                      ? "DOWNLOADED"
                                      : "DOWNLOAD THE MAGAZINE"
                                  }
                                  buttonClassName="primary pink"
                                  buttonId="downloadSubmitButton"
                                  url={path}
                                  endpoint={
                                    process.env.HUBSPOT_MAGAZINE_ENDPOINT
                                  }
                                  afterSubmit={(response, items) =>
                                    handleAfterSubmit(
                                      cta.subtext[0],
                                      hero.heroExcerpt,
                                      response,
                                      items
                                    )
                                  }
                                  extradata={{
                                    name: "magazine_name",
                                    value: "Edition 3",
                                  }}
                                  IpStackData
                                  formName={`Magazine - Edition 3`}
                                  noCaptchaValidation
                                />
                              ) : (
                                <>
                                  <div className="text-center mb24">
                                    <img
                                      src={Popper}
                                      alt="popper"
                                      className="mb24 popper-img"
                                    />

                                    <h3 className="mb24 fw-bold font-white">
                                      Success!
                                    </h3>

                                    <div className="mb50">
                                      <p className="p16 fade-white">
                                        {hero.heroCtaText}
                                      </p>
                                    </div>
                                    <Buttons
                                      theme="primary pink"
                                      text={cta.text[1]}
                                      onClick={() => {
                                        {
                                          toggleIsOpen(cta.subtext[0])
                                        }
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            {/* <div className="bugle-box mt25  down-to-up-5">
                              <div className="img-wrapper mb24">
                                <img
                                  src={bugle.image[0].url}
                                  className="w-100"
                                />
                              </div>
                              <p className="m-0 d-inline">{bugle.text[0]}</p>
                              <a href={`mailto:${bugle.text[1]}`} className="mail text-underline">
                                {bugle.text[1]}
                              </a>
                            </div> */}
                          </Col>
                        </div>
                        {success && (
                          <div className="m-cta d-flex flex-wrap down-to-up-2">
                            <Col lg={8} className="d-flex flex-wrap ">
                              <div className="m-cta-1-1 down-to-up-2">
                                <img
                                  src={cta.image[0].url}
                                  alt="cta"
                                  className="w-100"
                                />
                              </div>
                              <div className="m-cta-1-2 down-to-up-2">
                                <h1 className="cta-head font-white fw-700 font-roboto">
                                  {cta.text[0]}
                                </h1>
                              </div>
                            </Col>
                            <Col lg={4} className="m-cta-2 down-to-up-2">
                              <div className="down-to-up-2">
                                <Buttons
                                  theme="primary pink"
                                  text={cta.text[1]}
                                  onClick={() => {
                                    {
                                      internalDownload(
                                        cta.subtext[1],
                                        cta.text[2]
                                      )
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                          </div>
                        )}
                        {isOpen && (
                          <FlipBook
                            link={link}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </Container>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Magazine
